export const environment = {
  production: false,
  imgURL: 'assets/images/',
  timestamp: "__timestamp__",
  assets: 'assets/',
  HOME_URL: "https://demo.shop.it.kaprion.net",
  DC_URL: "https://demo.shop.it.kaprion.net/DatabaseController",
  APP_URL: "didcomm://demo.shop.it.kaprion.net/DatabaseController",
  PROCESSENGINE: "https://mags.kt.it.kaprion.org",
  CONFIGURATION: "integration",
  SYSTEM_NAME: 'Integration System'
};